import { computed, ref, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useTourType from './useTourType';

const useTourTypeList = () => {
  const toast = useToast();
  const { getTourTypeList } = useTourType();

  const perPageOptions = [10, 25, 50, 100];
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'passengerNum', label: 'passengers', sortable: true },
    { key: 'rate', sortable: true },
    { key: 'driverRate', sortable: true },
    { key: 'actions' },
  ];

  const pageNumber = ref(1);
  const orderDesc = ref(true);
  const pageSize = ref(10);
  const refTourTypeTable = ref(null);
  const orderBy = ref('id');
  const totalTourTypes = ref(0);

  const dataMeta = computed(() => {
    const localItemsCount = refTourTypeTable.value
      ? refTourTypeTable.value.localItems.length
      : 0;

    return {
      from: pageSize.value * (pageNumber.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (pageNumber.value - 1) + localItemsCount,
      of: totalTourTypes.value,
    };
  });

  watch([pageNumber, pageSize], () => {
    refTourTypeTable.value.refresh();
  });

  const fetchTourTypeList = (_, callback) => {
    const params = {
      orderBy: orderBy.value,
      orderDesc: orderDesc.value,
      pageSize: pageSize.value,
      pageNumber: pageNumber.value,
    };

    getTourTypeList(params)
      .then(({ tourTypes, total }) => {
        callback(tourTypes);
        totalTourTypes.value = total;
      })
      .catch(() => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Error fetching tour type list',
            icon: 'XCircleIcon',
          },
        });
      });
  };

  return {
    // Const
    perPageOptions,
    tableColumns,

    // Reactive
    pageNumber,
    orderDesc,
    pageSize,
    refTourTypeTable,
    orderBy,
    totalTourTypes,

    // Computed
    dataMeta,

    // Method
    fetchTourTypeList,
  };
};

export default useTourTypeList;
