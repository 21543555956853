<template>
  <div>
    <tour-type-form
      v-if="isAddNewTourTypeSidebarActive"
      :is-add-new-tour-type-sidebar-active.sync="isAddNewTourTypeSidebarActive"
      :tour-type="tourType"
      @form-submitted="formSubmitted"
    />

    <b-card no-body>
      <div class="m-2">
        <div class="d-flex align-items-center justify-content-between">
          <h4>Press the button to reset the vouchers numbers.</h4>

          <b-button
            variant="warning"
            size="md"
            @click="resetVouchers"
          >
            <span class="text-nowrap">Reset Vouchers Num.</span>
          </b-button>
        </div>
      </div>
    </b-card>

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>

            <v-select
              v-model="pageSize"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="openSidebar"
              >
                <span class="text-nowrap">Add Tour Type</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="tour-type-table"
        ref="refTourTypeTable"
        class="position-relative"
        :items="fetchTourTypeList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="orderDesc"
        style="min-height:110px"
      >
        <!-- Column: Name -->
        <template #cell(name)="data">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </span>
        </template>

        <!-- Column: Passenger Number -->
        <template #cell(passengerNum)="data">
          <span>{{ data.item.passengerNum }}</span>
        </template>

        <!-- Column: Rate -->
        <template #cell(rate)="data">
          <span>£{{ data.item.rate }}</span>
        </template>

        <!-- Column: Driver Rate -->
        <template #cell(driverRate)="data">
          <span>£{{ data.item.driverRate }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="editTourType(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalTourTypes"
              :per-page="pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BPagination, BRow, BTable } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import Swal from 'sweetalert2';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import TourTypeForm from '@/modules/frontier-tour-settings/components/TourTypeForm.vue';
import useTour from '@/modules/frontier-tour/composables/useTour'
import useTourTypeList from '@/modules/frontier-tour-settings/composables/useTourTypeList';
import vSelect from 'vue-select';

export default {
  setup() {
    const toast = useToast();
    const { resetVouchersNum } = useTour();
    const {
      dataMeta,
      fetchTourTypeList,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refTourTypeTable,
      tableColumns,
      totalTourTypes,
    } = useTourTypeList();

    const emptyTourType = { id: null, name: null };

    const isAddNewTourTypeSidebarActive = ref(false);
    const tourType = ref({ ...emptyTourType });

    const editTourType = (tourTypeSelected) => {
      tourType.value = { ...tourTypeSelected };
      isAddNewTourTypeSidebarActive.value = true;
    };

    const formSubmitted = () => {
      isAddNewTourTypeSidebarActive.value = false;
      tourType.value = { ...emptyTourType };
      refTourTypeTable.value.refresh();
    };

    const openSidebar = () => {
      tourType.value = { ...emptyTourType };
      isAddNewTourTypeSidebarActive.value = true;
    };

    const resetVouchers = async () => {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "If you reset the voucher numbers, they can be used again for new tours.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, reset it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await resetVouchersNum();

      toast.success({
        component: ToastificationContent,
        props: {
          title: 'Voucher numbers has been reseted successfully!',
          icon: 'CheckIcon',
        },
      });
    }

    return {
      // Reactive
      tourType,
      isAddNewTourTypeSidebarActive,

      //Method
      editTourType,
      formSubmitted,
      openSidebar,
      resetVouchers,

      // UseTourTypeList
      dataMeta,
      fetchTourTypeList,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refTourTypeTable,
      tableColumns,
      totalTourTypes,
    };
  },
  components: {
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    TourTypeForm,
    vSelect,
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

#tour-type-table {
  min-height: 150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>