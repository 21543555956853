<template>
  <b-sidebar
    id="add-new-tour-type-sidebar"
    :visible="isAddNewTourTypeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-tour-type-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ tourTypeData.id ? 'Update' : 'Add New' }} Tour Type
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="
            resetForm();
            hide();
          "
        />
      </div>

      <validation-observer ref="newTourTypeForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validationForm()"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="tourTypeData.name"
                :state="errors.length > 0 ? false : null"
                name="name"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="Passengers"
            rules="required"
          >
            <b-form-group
              label="Passengers"
              label-for="passenger-num"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    icon="UsersIcon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="tourTypeData.passengerNum"
                  id="passenger-num"
                  type="number"
                  step="1"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Rate"
            rules="required"
          >
            <b-form-group
              label="Rate"
              label-for="rate"
            >
              <b-input-group prepend="£">
                <b-form-input
                  v-model="tourTypeData.rate"
                  id="rate"
                  type="number"
                  step=".01"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Driver Rate"
            rules="required"
          >
            <b-form-group
              label="Driver Rate"
              label-for="driver-rate"
            >
              <b-input-group prepend="£">
                <b-form-input
                  v-model="tourTypeData.driverRate"
                  id="driver-rate"
                  type="number"
                  step=".01"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
              />
              {{ tourTypeData.id ? 'Update' : 'Add' }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="
                resetForm();
                hide();
              "
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ref } from '@vue/composition-api';
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BSidebar,
  BSpinner,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import store from '@/store';
import vSelect from 'vue-select';

export default {
  props: {
    isAddNewTourTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    tourType: {
      type: Object,
      require: true,
    },
  },
  setup(props, ctx) {
    const tourTypeData = ref(props.tourType);
    const newTourTypeForm = ref(null);
    const isLoading = ref(false);

    const resetForm = () => {
      newTourTypeForm.value.reset();
    };

    const validationForm = async () => {
      if (isLoading.value) return;

      isLoading.value = true;

      const valid = await newTourTypeForm.value.validate();
      if (!valid) {
        isLoading.value = false;
        return;
      }

      tourTypeData.value.id
        ? await store.dispatch('tourType/update', tourTypeData.value)
        : await store.dispatch('tourType/create', tourTypeData.value);

      ctx.emit('form-submitted');

      isLoading.value = false;
    };

    return {
      // Reactive
      tourTypeData,
      newTourTypeForm,
      isLoading,

      // Method
      resetForm,
      validationForm,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BSpinner
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-tour-type-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
